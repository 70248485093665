import { Form, Select, TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import ClearIcon from '../../../svgs/ClearIcon';
import SearchIcon from '../../../svgs/SearchIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { useQuery } from 'react-query';
import { getRightsList } from '../../../../api/rights';
import {
  convertToRightTreeList,
  mergeUniqueStringArray,
  getCountriesList,
  getCountryLabel,
} from '../../../../utils/global';
import { RightI } from '../../../../interfaces/right';
import { getCustomersList } from '../../../../api/customers';
import { CustomerI } from '../../../../interfaces/customer';
import { doGetFilmDetails, doGetFilmsList } from '../../../../api/films';
import { FilmI } from '../../../../interfaces/film';
import { TerritoryI } from '../../../../interfaces/territory';
import { getSEACountries } from '../../../constants';
import './Filter.scss';

type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

const LicensesFilterForm: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const [form] = Form.useForm();
  const [treeList, setTreeList] = useState<RightI[]>();
  const newCountryList = useMemo(() => getCountriesList(true), []);
  const { data: rights, isLoading: isRightLoading } = useQuery(['rights', 'list'], () => getRightsList({}));
  const { data: customers, isFetching: isCustomerFetching } = useQuery(['customers', 'list'], () =>
    getCustomersList({}),
  );
  const { data: films, isFetching: isFilmFetching } = useQuery(['films', 'list'], () => doGetFilmsList({}));

  const handleOnFinish = (values: any) => {
    const newValues = values;
    let newCountryCodes: string[] = values;
    if (values.territories && values.territories.includes('SEA')) {
      const sea = getSEACountries;
      const territoriesWithoutSEA = values.territories.filter((item: string) => item !== 'SEA');
      newCountryCodes = mergeUniqueStringArray(sea, territoriesWithoutSEA);
    } else {
      newCountryCodes = values.territories;
    }
    const newTerritories = newCountryCodes?.map((item: string) => getCountryLabel(newCountryList, item));
    newValues.territories = newTerritories;
    handleFilter(newValues);
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };
  const [newRightList, setNewRightList] = useState<RightI[]>();
  const onFilmChange = async (filmId: number) => {
    let rights: RightI[] = [];
    if (filmId) {
      const film = await doGetFilmDetails(filmId);
      if (film) {
        film.territories.forEach((territory: TerritoryI) => {
          const exclusiveRights = territory.exclusiveRights.map((right: RightI) => right);
          if (exclusiveRights) {
            rights = [...rights, ...exclusiveRights];
          }
          const nonExclusiveRights = territory.nonExclusiveRights.map((right: RightI) => right);
          if (nonExclusiveRights) {
            rights = [...rights, ...nonExclusiveRights];
          }
        });
      }
      const newRights = convertToRightTreeList(rights);
      setNewRightList(newRights);
    } else {
      form.resetFields(['rightIds']);
      setNewRightList(undefined);
    }
  };

  useEffect(() => {
    if (rights?.data) {
      const newData = convertToRightTreeList(rights?.data);
      setTreeList(newData);
    }
  }, [rights?.data]);

  return (
    <div className="licenses-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
            <Form.Item name="customerId">
              <Select
                placeholder="Customer"
                showSearch
                optionFilterProp="children"
                loading={isCustomerFetching}
                allowClear
              >
                {customers?.data?.map((item: CustomerI) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="filmId">
              <Select
                placeholder="Film"
                showSearch
                optionFilterProp="children"
                loading={isFilmFetching}
                allowClear
                onChange={onFilmChange}
              >
                {films?.data?.map((item: FilmI) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="territories">
              <Select
                placeholder="Territories"
                showSearch
                optionFilterProp="label"
                options={newCountryList}
                mode="multiple"
                allowClear
              />
            </Form.Item>
            <Form.Item name="rightIds">
              <TreeSelect
                showSearch
                treeNodeFilterProp="title"
                placeholder="Rights"
                allowClear
                treeDefaultExpandAll
                treeData={newRightList || treeList}
                loading={isRightLoading}
                multiple={true}
              />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LicensesFilterForm;
