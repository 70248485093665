import { pathToUrl } from './../utils/router';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import { BillingI } from '../interfaces/billing';

export const useGetBillingsList = () => useFetch<GetListResponse<BillingI>>('billings');

export const useGetBillingDetails = (id: number) => useFetch<BillingI>(`billings/${id}`);

export const useCreateBilling = (updater: (oldData: BillingI[], newData: BillingI) => BillingI[]) => {
  return usePost<BillingI[], BillingI>(pathToUrl('billings'), undefined, updater);
};

export const doGetBillingsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: Partial<GetListResponse<BillingI>> = await api.get(`billings?${newParams}`);
  return response;
};

export const doCreateBilling = async (values: BillingI) => {
  const { data: response }: any = await api.post('billings', values);
  return response.data;
};

export const doUpdateBilling = async ({ id, ...values }: { id: string; values: BillingI }) => {
  const { data: response }: any = await api.patch(`billings/${id}`, values);
  return response.data;
};

export const doDeleteBilling = async (id: number) => {
  const { data: response }: any = await api.delete(`billings/${id}`);
  return response.data;
};
